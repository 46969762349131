import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Fab from '@mui/material/Fab';
import SendIcon from '@mui/icons-material/Send';
import AddLocationIcon from '@mui/icons-material/AddLocation';

import { Typography } from '@mui/material';
// Keyframes pour les animations
import { keyframes } from '@emotion/react'


export default function TutorialMap2(props) {

  //
  // Animations standard
  //

  let buttonEffect = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }`


  function PreviousButton() {
    return (
      <Button
        onClick={() => props.goToTutorialPageFunction({page : "Map1"})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateBeforeIcon />
      </Button>
    );
  }

  function NextButton() {
    return (
      <Button
        onClick={() => props.goToTutorialPageFunction({page : "Map3"})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateNextIcon />
      </Button>
    );
  }

  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
        <Typography variant = "h6" color={"primary"} paragraph={true}>
            Créer facilement des contenus
          </Typography>
          <Typography color={"primary"} paragraph={true}>
            <Fab color="secondary"
              sx={{
                marginRight : 2,
                animation: `${buttonEffect} 0.5s ease infinite`
              }}
            >
              <SendIcon sx={{color : "white"}}/>
            </Fab>
            Publiez dans plusieurs spots à la fois
          </Typography>
          <Typography color={"primary"}  paragraph={true}>
            <Fab color="secondary"
              sx={{
                marginRight : 2,
                animation: `${buttonEffect} 0.5s ease infinite`
              }}
            >
              <AddLocationIcon sx={{color : "white"}}/>
            </Fab>
            Signalez des évènements en les épinglant sur la carte
          </Typography>

        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {PreviousButton()}
        {NextButton()}
      </DialogActions>
    </Dialog>
  );
}